<template>
    <form-table
        :table-header-label="'Components'"
        :add-row-label="'Component'"
        :table-contents="tableData"
        @add-row="addTableRow"
    >
        <template #headings>
            <th>Component Name</th>
            <th>Cas Number</th>
            <th>% W/W</th>
            <th>#</th>
        </template>

        <template #rows="{data}">
            <tr v-for="component, index in data" :key="component.id">
                <td v-if="component.name === ''">
                    <dynamic-select
                        name="component_id"
                        placeholder="Select Component"
                        :route='route'
                        @update:modelValue="selectComponent(index, $event)"
                    ></dynamic-select>
                </td>
                <td v-else>
                    <input class="form-control" type="text" v-model="component.name" disabled>
                </td>
                <td><input class="form-control" type="text" v-model="component.cas_number" disabled></td>
                <td><input class="form-control" type="text" v-model="component.percentage" required></td>
                <td>
                    <button type="button" class="btn btn-sm btn-danger" @click="removeRow(index)"><i class="fa fa-xmark"></i></button>
                </td>
            </tr>
        </template>
    </form-table>
    <input type="hidden" name="components" :value="serializedTableData">
</template>

<script setup>
import { reactive, computed, onMounted } from "vue";
import FormTable from '@/components/dynamicFormTable.vue';
import DynamicSelect from '@/components/dynamicSelect.vue';

const props = defineProps({
    tableData: {
        type: String,
        default: () => [],
    },
    selectRoute: {
        type: String,
        required: true,
    },
});

const tableData = reactive(JSON.parse(props.tableData || '[]'));
const route = props.selectRoute;

const addTableRow = () => {
    tableData.push({
        id: null,
        name: '',
        cas_number: '',
        percentage: '',
    });
};

const removeRow = (index) => {
    tableData.splice(index, 1);
};

const selectComponent = (index, selectedComponent) => {
    if (selectedComponent) {
        tableData[index].id = selectedComponent.id;
        tableData[index].name = selectedComponent.text;
        tableData[index].cas_number = selectedComponent.cas_number;
    }
};

const serializedTableData = computed(() => JSON.stringify(tableData));

onMounted(() => {
    Eventbus.emit('watch-me');
});
</script>